Ember.TEMPLATES["javascripts/discourse/connectors/composer-fields-below/shared-edit-buttons"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"creatingSharedEdit\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"leave-shared-edit\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"tabindex\",\"6\"],[12,\"title\",[28,\"i18n\",[\"shared_edits.done\"],null]],[3,\"action\",[[23,0,[]],\"endSharedEdit\"]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"btn btn-primary\"],[12,\"tabindex\",\"6\"],[3,\"action\",[[23,0,[]],\"endSharedEdit\"]],[8],[1,[28,\"i18n\",[\"shared_edits.done\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/composer-fields-below/shared-edit-buttons"}});
define("discourse/plugins/discourse-shared-edits/discourse/connectors/composer-fields-below/shared-edit-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    actions: {
      endSharedEdit: function endSharedEdit() {
        this.appEvents.trigger("composer:close");
      }
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-shared-edits/initializers/shared-edits-init", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "../lib/shared-edits", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "discourse/models/composer"], function (_exports, _pluginApi, _decorators, _sharedEdits, _ajax, _ajaxError, _object, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var SHARED_EDIT_ACTION = "sharedEdit";
  var PLUGIN_ID = "discourse-shared-edits";

  function replaceButton(buttons, find, replace) {
    var idx = buttons.indexOf(find);

    if (idx !== -1) {
      buttons[idx] = replace;
    }
  }

  function initWithApi(api) {
    var _dec, _obj, _dec2, _obj2, _dec3, _obj3, _dec4, _dec5, _obj4;

    _composer.SAVE_LABELS[SHARED_EDIT_ACTION] = "composer.save_edit";
    _composer.SAVE_ICONS[SHARED_EDIT_ACTION] = "pencil-alt";
    api.includePostAttributes("shared_edits_enabled");
    api.addPostClassesCallback(function (attrs) {
      if (attrs.shared_edits_enabled && attrs.canEdit) {
        return ["shared-edits-post"];
      }
    });
    api.addPostMenuButton("sharedEdit", function (post) {
      if (!post.shared_edits_enabled || !post.canEdit) {
        return;
      }

      var result = {
        action: SHARED_EDIT_ACTION,
        icon: "far-edit",
        title: "shared_edits.button_title",
        className: "shared-edit create fade-out",
        position: "last"
      };

      if (!post.mobileView) {
        result.label = "shared_edits.edit";
      }

      return result;
    });
    api.removePostMenuButton("edit", function (attrs) {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.removePostMenuButton("wiki-edit", function (attrs) {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.reopenWidget("post-menu", {
      menuItems: function menuItems() {
        var result = this._super.apply(this, arguments); // wiki handles the reply button on its own. If not a wiki and is shared-edit
        // remove the label from the reply button.


        if (this.attrs.shared_edits_enabled && this.attrs.canEdit && !this.attrs.wiki) {
          replaceButton(result, "reply", "reply-small");
        }

        return result;
      },
      sharedEdit: function sharedEdit() {
        var post = this.findAncestorModel();
        this.appEvents.trigger("shared-edit-on-post", post);
      }
    });
    api.reopenWidget("post-admin-menu", {
      html: function html(attrs) {
        var contents = this._super.apply(this, arguments);

        if (!this.currentUser.staff || !contents.children) {
          return contents;
        }

        contents.children.push(this.attach("post-admin-menu-button", {
          action: "toggleSharedEdit",
          icon: "far-edit",
          className: "admin-collude",
          label: attrs.shared_edits_enabled ? "shared_edits.disable_shared_edits" : "shared_edits.enable_shared_edits"
        }));
        return contents;
      },
      toggleSharedEdit: function toggleSharedEdit() {
        var _this = this;

        var post = this.findAncestorModel();
        var url = "/shared_edits/p/".concat(post.id, "/").concat(post.shared_edits_enabled ? "disable" : "enable", ".json");
        (0, _ajax.ajax)(url, {
          type: "PUT"
        }).then(function () {
          post.set("shared_edits_enabled", post.shared_edits_enabled ? false : true);

          _this.scheduleRerender();
        }).catch(_ajaxError.popupAjaxError);
      }
    });
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      sharedEdit: function sharedEdit() {
        this.appEvents.trigger("shared-edit-on-post");
      }
    });
    api.modifyClass("controller:history", (_dec = (0, _decorators.default)("post.shared_edits_enabled"), (_obj = {
      pluginId: PLUGIN_ID,
      editButtonLabel: function editButtonLabel(sharedEdit) {
        var label = this._super.apply(this, arguments);

        if (sharedEdit) {
          label = "post.revisions.controls.edit_post";
        }

        return label;
      },
      actions: {
        editPost: function editPost() {
          if (this.post.shared_edits_enabled) {
            this.appEvents.trigger("shared-edit-on-post", this.post);
            this.send("closeModal");
          } else {
            this._super.apply(this, arguments);
          }
        }
      }
    }, (_applyDecoratedDescriptor(_obj, "editButtonLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "editButtonLabel"), _obj)), _obj)));
    api.modifyClass("model:composer", (_dec2 = (0, _decorators.default)("action"), (_obj2 = {
      pluginId: PLUGIN_ID,
      creatingSharedEdit: _object.computed.equal("action", SHARED_EDIT_ACTION),
      editingPost: function editingPost() {
        return this._super.apply(this, arguments) || this.creatingSharedEdit;
      }
    }, (_applyDecoratedDescriptor(_obj2, "editingPost", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "editingPost"), _obj2)), _obj2)));
    api.modifyClass("component:composer-presence-display", {
      pluginId: PLUGIN_ID,
      _typing: function _typing() {
        if (this.model.action === SHARED_EDIT_ACTION) {
          var lastKey = this.model.lastKeyPress;

          if (!lastKey || lastKey < Date.now() - 2000) {
            return;
          }
        }

        this._super.apply(this, arguments);
      }
    });
    api.modifyClass("component:composer-editor", (_dec3 = (0, _decorators.on)("keyDown"), (_obj3 = {
      pluginId: PLUGIN_ID,
      _trackTyping: function _trackTyping() {
        if (this.composer.action === SHARED_EDIT_ACTION) {
          this.composer.set("lastKeyPress", Date.now());
        }
      }
    }, (_applyDecoratedDescriptor(_obj3, "_trackTyping", [_dec3], Object.getOwnPropertyDescriptor(_obj3, "_trackTyping"), _obj3)), _obj3)));
    api.modifyClass("controller:topic", {
      pluginId: PLUGIN_ID,
      init: function init() {
        var _this2 = this;

        this._super.apply(this, arguments);

        this.appEvents.on("shared-edit-on-post", function (post) {
          var draftKey = post.get("topic.draft_key");
          var draftSequence = post.get("topic.draft_sequence");

          _this2.get("composer").open({
            post: post,
            action: SHARED_EDIT_ACTION,
            draftKey: draftKey,
            draftSequence: draftSequence
          });
        });
      },
      willDestroy: function willDestroy() {
        this.appEvents.off("shared-edit-on-post", this);

        this._super.apply(this, arguments);
      }
    });
    api.modifyClass("controller:composer", (_dec4 = (0, _decorators.on)("init"), _dec5 = (0, _decorators.observes)("model.reply"), (_obj4 = {
      pluginId: PLUGIN_ID,
      open: function open(opts) {
        var _this3 = this;

        var openResponse = this._super(opts);

        if (openResponse && openResponse.then) {
          return openResponse.then(function () {
            if (opts.action === SHARED_EDIT_ACTION) {
              (0, _sharedEdits.setupSharedEdit)(_this3.model);
            }
          });
        }
      },
      collapse: function collapse() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return this.close();
        }

        return this._super();
      },
      close: function close() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          (0, _sharedEdits.teardownSharedEdit)(this.model);
        }

        return this._super();
      },
      save: function save() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return this.close();
        }

        return this._super.apply(this, arguments);
      },
      _listenForClose: function _listenForClose() {
        var _this4 = this;

        this.appEvents.on("composer:close", function () {
          _this4.close();
        });
      },
      _handleSharedEdit: function _handleSharedEdit() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          (0, _sharedEdits.performSharedEdit)(this.model);
        }
      },
      _saveDraft: function _saveDraft() {
        if (this.get("model.action") === SHARED_EDIT_ACTION) {
          return;
        }

        return this._super();
      }
    }, (_applyDecoratedDescriptor(_obj4, "_listenForClose", [_dec4], Object.getOwnPropertyDescriptor(_obj4, "_listenForClose"), _obj4), _applyDecoratedDescriptor(_obj4, "_handleSharedEdit", [_dec5], Object.getOwnPropertyDescriptor(_obj4, "_handleSharedEdit"), _obj4)), _obj4)));
  }

  var _default = {
    name: "discourse-shared-edits",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (!siteSettings.shared_edits_enabled) {
        return;
      }

      (0, _pluginApi.withPluginApi)("0.8.6", initWithApi);
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-shared-edits/lib/shared-edits", ["exports", "discourse/lib/ajax", "@ember/object", "discourse/lib/ajax-error", "discourse/lib/load-script", "@ember/runloop"], function (_exports, _ajax, _object, _ajaxError, _loadScript, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setupSharedEdit = setupSharedEdit;
  _exports.teardownSharedEdit = teardownSharedEdit;
  _exports.performSharedEdit = performSharedEdit;
  var THROTTLE_SAVE = 500;

  function setupSharedEdit(composer) {
    var manager = SharedEditManager.create();
    composer.set("sharedEditManager", manager);
    (0, _ajax.ajax)("/shared_edits/p/".concat(composer.post.id)).then(function (data) {
      manager.set("version", data.version);
      manager.set("raw", data.raw);
      manager.set("composer", composer);
      composer.set("reply", data.raw);
      manager.subscribe();
    }).catch(_ajaxError.popupAjaxError);
  }

  var loadedTextUnicode = false;

  function teardownSharedEdit(composer) {
    var post = composer.post;
    var manager = composer.sharedEditManager;

    if (manager) {
      manager.commit();
    }

    composer.messageBus.unsubscribe("/shared_edits/".concat(post.id));
    composer.set("sharedEditManager.composer", null);
    composer.set("sharedEditManager", null);
  }

  function performSharedEdit(composer) {
    if (composer.sharedEditManager) {
      composer.sharedEditManager.performSharedEdit();
    }
  }

  function diff(before, after) {
    var diffLib = window.otLib.default.OtDiff.diff;
    var changes = diffLib(before, after);
    return compress(changes);
  }

  function compress(change) {
    var compressed = [];

    if (change.action !== "noop") {
      if (change.start > 0) {
        compressed.push(change.start);
      }

      switch (change.action) {
        case "replace":
          compressed.push({
            d: change.remove
          });
          compressed.push(change.payload);
          break;

        case "insert":
          compressed.push(change.payload);
          break;

        case "delete":
          compressed.push({
            d: change.remove
          });
          break;
      }
    }

    return compressed;
  }

  var SharedEditManager = _object.default.extend({
    raw: null,
    version: null,
    submittedChanges: null,
    pendingChanges: null,
    ajaxInProgress: false,
    commit: function commit() {
      (0, _ajax.ajax)("/shared_edits/p/".concat(this.composer.post.id, "/commit"), {
        method: "PUT"
      }).catch(_ajaxError.popupAjaxError);
    },
    performSharedEdit: function performSharedEdit() {
      var _this = this;

      if (loadedTextUnicode) {
        this.sendDiffThrottled();
      } else {
        (0, _loadScript.default)("/plugins/discourse-shared-edits/javascripts/text-unicode-dist.js").then(function () {
          loadedTextUnicode = true;

          _this.sendDiffThrottled();
        });
      }
    },
    sendDiffThrottled: function sendDiffThrottled() {
      (0, _runloop.throttle)(this, "sendDiff", THROTTLE_SAVE, false);
    },
    sendDiff: function sendDiff() {
      var _this2 = this;

      var composer = this.composer;

      if (!composer) {
        return;
      }

      if (this.ajaxInProgress) {
        this.sendDiffThrottled();
        return;
      }

      var changes = diff(this.raw, composer.reply);
      var submittedRaw = composer.reply;

      if (changes.length > 0) {
        this.ajaxInProgress = true;
        (0, _ajax.ajax)("/shared_edits/p/".concat(composer.post.id), {
          method: "PUT",
          data: {
            revision: JSON.stringify(changes),
            version: this.version,
            client_id: composer.messageBus.clientId
          }
        }).then(function (result) {
          var inProgressChanges = diff(submittedRaw, composer.reply);

          _this2.applyRevisions(result.revisions, inProgressChanges);
        }).finally(function () {
          _this2.ajaxInProgress = false;
        });
      }
    },
    applyRevisions: function applyRevisions(revs, inProgressChanges) {
      var _this3 = this;

      var currentChanges = inProgressChanges || diff(this.raw, this.composer.reply);
      var newRaw = this.raw;
      var newVersion = this.version;
      var otUnicode = window.otLib.default.OtUnicode;
      var newChanges = [];
      revs.forEach(function (revision) {
        if (revision.version === newVersion + 1) {
          var parsedRevision = JSON.parse(revision.revision);
          newRaw = otUnicode.apply(newRaw, parsedRevision);
          newVersion = revision.version;

          if (revision.client_id !== _this3.composer.messageBus.clientId) {
            newChanges = otUnicode.compose(newChanges, parsedRevision);
            currentChanges = otUnicode.transform(currentChanges, parsedRevision, "left");
          }
        }
      });
      this.set("raw", newRaw);
      this.set("version", newVersion);

      if (currentChanges.length > 0) {
        newRaw = otUnicode.apply(newRaw, currentChanges);
      }

      if (newRaw !== this.composer.reply) {
        var input = document.querySelector("#reply-control textarea.d-editor-input");

        if (input.selectionStart) {
          var selLength = input.selectionEnd - input.selectionStart;
          var position = otUnicode.transformPosition(input.selectionStart, newChanges); // still need to compensate for scrollHeight changes
          // but at least this is mostly stable

          var scrollTop = input.scrollTop;
          input.value = newRaw;
          input.selectionStart = position;
          input.selectionEnd = position + selLength;
          window.requestAnimationFrame(function () {
            input.scrollTop = scrollTop;
          });
        }

        this.composer.set("reply", newRaw);
      }
    },
    subscribe: function subscribe() {
      var _this4 = this;

      var composer = this.composer;
      var post = composer.post;
      composer.messageBus.subscribe("/shared_edits/".concat(post.id), function (message) {
        if (message.client_id !== composer.messageBus.clientId && !_this4.ajaxInProgress) {
          _this4.applyRevisions([message]);
        }
      });
    }
  });
});

